import { init } from "@bloglovin/vue-framework";

import "@bloglovin/vue-component-library/style.css";

import Test from "@/views/Test";
import Login from "@/views/Login";
import Signup from "@/views/Signup";
import ForgotPassword from "@/views/ForgotPassword";
import ResetPassword from "@/views/ResetPassword";
import VerifyEmail from "@/views/VerifyEmail";
import ChangePassword from "@/views/ChangePassword";

const components = [
  Test,
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  ChangePassword,
];

const jwt = window.blclient.data.JWT;
init(process.env.NODE_ENV, process.env.PROJECT_NAME, components, jwt, window.blclient.data.links);
