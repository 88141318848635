import { EMAIL_REGEX, PASSWORD_REGEX, USERNAME_REGEX } from "@/constants/regex-constants";
import {
    INVALID_EMAIL_ERROR,
    INVALID_PASSWORD_LENGTH_ERROR,
    INVALID_PASSWORD_MATCH_ERROR,
    INVALID_PASSWORD_PATTERN_ERROR,
    INVALID_USERNAME_LENGTH_ERROR,
    INVALID_USERNAME_PATTERN_ERROR
} from "@/constants/error-constants";

export function validateRegex(string, pattern, error) {
    let test = string.match(pattern);
    let resultError = null;
    if (!test) {
        resultError = error;
    }
    return resultError;
}

export function validatePassword(password) {
    if (password && password.length < 8) {
        return INVALID_PASSWORD_LENGTH_ERROR;
    }
    return validateRegex(password, PASSWORD_REGEX, INVALID_PASSWORD_PATTERN_ERROR);
}

export function validateEmail(email) {
    return validateRegex(email, EMAIL_REGEX, INVALID_EMAIL_ERROR);
}

export function validateUsername(username) {
    if (username && username.length < 3) {
        return INVALID_USERNAME_LENGTH_ERROR;
    }
    return validateRegex(username, USERNAME_REGEX, INVALID_USERNAME_PATTERN_ERROR);
}

export function validateConfirmPassword(confirmPassword, password) {
    return password !== confirmPassword ? INVALID_PASSWORD_MATCH_ERROR : null;
}
