<template>
  <FormWrapper>
    <DisplayHeader :subtle="true" text="Change Password" />
    <TextInput
      v-model="password"
      name="password"
      placeholder="***********"
      label="current password"
      :required="true"
      type="password"
    />
    <TextInput
      v-model="newPassword"
      name="newPassword"
      placeholder="***********"
      label="new password"
      :required="true"
      type="password"
      @blur="validateNewPassword"
    />
    <TextInput
      v-model="newPasswordRepeated"
      name="confirmNewPassword"
      placeholder="***********"
      label="confirm password"
      :required="true"
      type="password"
      @blur="validateConfirmNewPassword"
    />
    <DisplayText v-if="passwordError" subtle :text="passwordError" />
    <span>
      <Btn type="submit" :disabled="passwordError" @click="submitChangePasswordForm">Save Changes</Btn>
    </span>
    <flexBox direction="horizontal" gap="10px">
      <TextLink href="/">
        Home
      </TextLink>
      <TextLink href="/login">
        Login
      </TextLink>
    </flexBox>
    <div>
      test user id: -> {{ userId }}
    </div>
  </FormWrapper>
</template>

<script>
import { mapActions } from "pinia";
import { validateConfirmPassword, validatePassword } from "@/helpers/validation-helpers";
import { useUserStore } from "@/stores/user-store";

export default {
  name: "ChangePassword",
  props: {
    userId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      password: "",
      newPassword: "",
      newPasswordRepeated: "",
      passwordError: null,
      error: null,
    };
  },
  methods: {
    ...mapActions(useUserStore, { passwordChange: "passwordChange" }),
    submitChangePasswordForm() {
      if (!this.passwordError) {
        this.passwordChange(this.userId, this.password, this.newPassword, this.newPasswordRepeated); 
      }
    },
    validateNewPassword(event) {
      const password = event.target.value;
      this.passwordError = validatePassword(password);
    },
    validateConfirmNewPassword(event) {
      const confirmNewPassword = event.target.value;
      this.passwordError = validateConfirmPassword(confirmNewPassword, this.newPassword);
    }
  }
};
</script>
<style scoped>
</style>
