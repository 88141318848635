<template>
  <FormWrapper>
    <DisplayHeader :subtle="true" text="ResetPassword" />
    <DisplayText :text="emailAddress" />
    <TextInput
      v-model="password"
      name="password"
      placeholder="***********"
      label="new password"
      :required="true"
      type="password"
      @blur="myValidatePassword"
    />
    <TextInput
      v-model="passwordRepeated"
      name="confirmPassword"
      placeholder="***********"
      label="confirm new password"
      :required="true"
      type="password"
      @blur="myValidateConfirmPassword"
    />
    <span class="login_error">
      <DisplayText v-if="formHasError" subtle :text="formError" />
    </span>
    <span>
      <Btn type="submit" :disabled="formHasError" @click="submit">Submit</Btn>
    </span>
  </FormWrapper>
</template>

<script>
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import { validateConfirmPassword, validatePassword } from "@/helpers/validation-helpers";

export default {
  name: "ResetPassword",
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      password: "",
      passwordRepeated: "",
      passwordError: null,
      emailError: null,
    };
  },

  computed: {
    formError() {
      return this.emailError ?? this.passwordError;
    },
    formHasError() {
      return this.formError !== null;
    }
  },
  methods: {
    ...mapActions(useUserStore, { resetPassword: "resetPassword" }),
    submit() {
      if (this.formHasError) {
        alert("you should never be allowed to push this button while there is an error");
        return;
      }
      this.resetPassword(this.userId, this.token, this.password, this.passwordRepeated);
    },
    myValidatePassword() {
      this.passwordError = validatePassword(this.password);
    },
    myValidateConfirmPassword() {
      this.passwordError = validateConfirmPassword(this.passwordRepeated, this.password);
    }
  }
};

</script>
<style scoped>
</style>
