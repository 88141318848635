<template>
  <FormWrapper>
    <DisplayHeader :subtle="true" text="Forgot Password" />
    <TextInput
      v-model="email"
      name="email"
      placeholder="name@somebrand.com"
      label="Email"
      :required="true"
      type="text"
      @blur="validateEmail"
    />
    <DisplayText v-if="emailError" :subtle="emailError" :text="emailError" />
    <span>
      <Btn
        type="submit"
        :disabled="emailError || emailSent"
        @click="submitForgotPasswordForm"
      >Send Verification Link
      </Btn>
    </span>
  </FormWrapper>
</template>
  
<script>
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import { validateEmail } from "@/helpers/validation-helpers";

export default {
  name: "ForgotPassword",
  props: [],
  data() {
    return {
      email: "",
      emailSent: false,
      emailError: null,
    };
  },
  methods: {
    ...mapActions(useUserStore, { forgotPassword: "forgotPassword" }),
    submitForgotPasswordForm() {
      if (this.emailError) {
        alert("you should not be able to submit form while there is an error");
      }
      this.forgotPassword(this.email);
      this.emailSent = true;
    },
    validateEmail() {
      this.emailError = validateEmail(this.email);
    },
  }
};

</script>
<style scoped>

</style>
