// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-blue[data-v-1e2ee26e] {
  color: steelblue;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/views/Test.vue","webpack://./Test.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;ACAF","sourcesContent":["\n.text-blue {\n  color: steelblue;\n}\n",".text-blue {\n  color: steelblue;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
