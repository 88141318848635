// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_error[data-v-ebaa6c86] {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/views/Login.vue","webpack://./Login.vue"],"names":[],"mappings":"AACE;EACE,UAAA;ACAJ","sourcesContent":["\n  .login_error {\n    color: red;\n  }\n",".login_error {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
