<template>
  <FormWrapper>
    <DisplayHeader :subtle="true" text="Login" />
    <TextInput
      v-model="form.email"
      name="email"
      placeholder="name@somebrand.com"
      label="email"
      :required="true"
      type="email"
    />
    <TextInput
      v-model="form.password"
      name="password"
      placeholder="***********"
      label="password"
      :required="true"
      type="password"
    />
    <div>
      <Btn type="submit" :disabled="formHasError" @click="submitLoginForm">
        Login
      </Btn>
    </div>
    <Column>
      <TextLink href="/forgot-password">
        Forgot password
      </TextLink>
      <TextLink href="/signup">
        create account
      </TextLink>
    </Column>
    <br>
    <div class="login_error">
      <DisplayText v-if="formHasError" subtle :text="formError" />
    </div>
  </FormWrapper>
</template>

<script>
import { mapActions }  from "pinia";
import { validateEmail, validatePassword } from "@/helpers/validation-helpers";
import { useUserStore } from "@/stores/user-store";

export default {
  name: "Login",
  props: [

  ],
  data () {
    return {
      form: {
        email: "", 
        password: ""
      },
      passwordError: null,
      emailError: null,
      result: null
    };
  },
  computed: {
    formError() {
      return this.emailError ?? this.passwordError;
    },
    formHasError() {
      return this.formError !== null;
    }
  },
  methods: {
    ...mapActions(useUserStore, { loginUser: "loginUser" }),
    submitLoginForm() {
      if (!this.formHasError) {
        this.loginUser(this.form);
      }
    },
    validateEmail(event) {
      const email = event.target.value;
      this.emailError = validateEmail(email);
    },
    validatePassword(event) {
      const password = event.target.value;
      this.passwordError = validatePassword(password);
    },
  }
};

</script>
<style scoped>
  .login_error {
    color: red;
  }
</style>
