<template>
  <FormWrapper>
    <DisplayHeader :subtle="true" text="Signup" />
    <TextInput
      v-model="form.email"
      name="email"
      placeholder="name@somebrand.com"
      label="email"
      :required="true"
      type="email"
      @blur="validateEmail"
    />
    <TextInput
      v-model="form.username"
      name="username"
      placeholder="John Doe"
      label="username"
      :required="true"
      type="text"
    />

    <TextInput
      v-model="form.password"
      name="password"
      placeholder="***********"
      label="password"
      :required="true"
      type="password"
      @blur="validatePassword"
    />
    <TextInput
      v-model="form.confirmNewPassword"
      name="confirmPassword"
      placeholder="***********"
      label="confirm password"
      :required="true"
      type="password"
      @blur="validateConfirmPassword"
    />
    <DisplayText v-if="formHasError" subtle :text="formError" />
    <span>
      <Btn type="submit" :disabled="formHasError" @click="submit">Sign Up</Btn>
    </span>
    <Row>
      <TextLink href="/forgot-password">
        Forgot password
      </TextLink>
      <TextLink href="/login">
        Login
      </TextLink>
      <DisplayText v-if="result" :text="result" />
    </Row>
  </FormWrapper>
</template>

<script>
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/user-store";
import { validateConfirmPassword, validateEmail, validatePassword, validateUsername } from "@/helpers/validation-helpers";

export default {
  name: "Signup",
  props: [
  ],
  data () {
    return {
      form: {
        username: "",
        email: "", 
        password: "",
        confirmNewPassword: ""
      },
      passwordError: null,
      emailError: null,
      usernameError: null,
      result: null
    };
  },
  computed: {
    formError() {
      return this.usernameError ?? this.emailError ?? this.passwordError;
    },
    formHasError() {
      return this.formError !== null;
    }
  },
  methods: {
    ...mapActions(useUserStore, { signupUser: "signupUser" }),
    submit() {
      if (!this.formHasError) {
        this.result = this.signupUser(this.form);
      }
    },
    validateUsername(event) {
      const username = event.target.value;
      this.usernameError = validateUsername(username);
    },
   
    validateEmail(event) {
      const email = event.target.value;
      this.emailError = validateEmail(email);
    },
    validatePassword(event) {
      const password = event.target.value;
      this.passwordError = validatePassword(password);
    },
    validateConfirmPassword(event) {
      const confirmPassword = event.target.value;
      this.passwordError = validateConfirmPassword(confirmPassword, this.form.password);
    }
  }
};

</script>
<style scoped>
</style>
