<template>
  <div class="text-blue">
    Hello world
  </div>
  <div>
    prop1 - {{ prop1 }}
  </div>
  <div>
    prop2 - {{ prop2 }}
  </div>
  <div>
    state1 - {{ state1 }}
  </div>
  <ul>
    <li>
      <a href="/login">Login</a>
    </li>
    <li>
      <a href="/logout">logout</a>
    </li>
    <li>
      <a href="/signup">Create a New Account</a>
    </li>
  </ul>
</template>

<script>

export default {
  name: "Test",
  props: {
    prop1: {
      type: String,
      required: true,
    },
    prop2: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      state1: "state value",
    };
  },
};
</script>

<style scoped>
.text-blue {
  color: steelblue;
}
</style>
