import { getRelativeTimeString } from "@/helpers/date-helpers";
import { $axios } from "@bloglovin/vue-framework";
import { ToastBuilder } from "@bloglovin/vue-component-library";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user-store", {
  state: () => ({
    userData: [],
    errorData: { error_message: "" },
    emailVerificationStatus: false,
    appId: 2,
  }),
  getters: {
    getUserData() {
      return this.userData;
    },
    getErrorData() {
      return this.errorData;
    },
    getToasts() {
      return this.toasts;
    },
    getEmailVerificationStatus() {
      return this.emailVerificationStatus;
    }
  },
  actions: {

    loginUser(form) {
      $axios.post("/user/login", JSON.stringify(
        { email: form.email, 
          password: form.password,
          app_id: this.appId 
        }
      ))
        .then((response) => {
          this.userData = response.data;
          if (!response.data.success) {
            if (response.data?.old_password) {
              new ToastBuilder().setTitle("Login Fail")
                  .setText("This is an old password changed " + getRelativeTimeString(response.data.date_changed) )
                  .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
                  .setTimeout(ToastBuilder.TIMEOUT_DEFAULT)
                  .setClosable(true)
                  .build();
            }
            if (response.data?.change_legacy_password) {
              $axios.post("/user/forgot-password", JSON.stringify({
                username_email: form.email,
              }))
                .then(response => {
                  this.userData = response.data;
                  let email = response.data.emails[0];
                  window.location.href = `/reset-password/?email_id=${email?.email_id}`;
                });
            }
            new ToastBuilder().setTitle("Login Fail")
                .setText("Username or password is incorrect")
                .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
                .setTimeout(ToastBuilder.TIMEOUT_DEFAULT)
                .setClosable(true)
                .build();
          } else {
            window.location.href = "/";
          }
        }).catch((error) => {
          new ToastBuilder().setTitle("Login Fail")
            .setText(error.response.data.message)
            .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
            .setTimeout(ToastBuilder.TIMEOUT_NONE)
            .setClosable(true)
            .build();
        }); 
    },

    signupUser(form) {
      return $axios.post("/org/create-with-new-owner", JSON.stringify({
        app_id: this.appId,
        email: form.email,
        username: form.username,
        org_name: form.username,
        password: form.password,
      }))
        .then(response => {
          if (!response.data.success) {
            new ToastBuilder().setTitle("Signup Fail")
              .setText(`Something went wrong with creating your account: ${response.data?.error}`)
              .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
              .setTimeout(ToastBuilder.TIMEOUT_DEFAULT)
              .setClosable(true)
              .build();
          }
          this.userData = response.data.userData;
          this.orgData = response.data.orgData;
          const userId = this.userData.user_id;
          const emailAddress = this.userData.email_data.email_address;
          const emailId = this.userData.email_data.email_id;
          const token = this.userData.email_data.token;
          alert("done");
          new ToastBuilder().setTitle("Signup Success")
            .setText("You have successfully signed up.\n"
              + `Please check your mailbox(${emailAddress}) to verify your email address`)
            .setToastType(ToastBuilder.TOAST_TYPE_SUCCESS)
            .setTimeout(ToastBuilder.TIMEOUT_DEFAULT)
            .setClosable(true)
            .build();
          new ToastBuilder().setTitle("Verify Email")
            .setText(`/verify-email?user_id=${userId}&email_id=${emailId}&token=${token}`)
            .setToastType(ToastBuilder.TOAST_TYPE_STANDARD)
            .setTimeout(ToastBuilder.TIMEOUT_NONE)
            .setClosable(true)
            .build();
          return response.data.userData;
        });
    },

    forgotPassword(email) {
      $axios.post(
        "/user/forgot-password/initiate",
        {
          email: email,
          app_id: 2,
        }
      ).then(response => {
        if (!response.data.success) {
          alert("something went wrong");
        }
        new ToastBuilder()
          .setTitle("Check your Email")
          .setToastType(ToastBuilder.TOAST_TYPE_SUCCESS)
          .setTimeout(ToastBuilder.TIMEOUT_NONE)
          .setClosable(true)
          .build();
      });
    },

    resetPassword(userId, token, password, passwordRepeated) {
      $axios.post(
        `/user/${userId}/forgot-password/complete/${token}`,
        {
          password: password,
          password_repeated: passwordRepeated,
        }
      ).then(() => {
        window.location.href = "/";
      }).catch((error) => {
        let errorMessage = "unknown error";
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        new ToastBuilder().setTitle("Reset Password Failed")
          .setText(errorMessage)
          .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
          .setTimeout(5000)
          .setClosable(true)
          .build();
      });
    },

    verifyEmail(userId, emailId, token) {
      return $axios.post(`/user/app/${this.appId}/verify-account`, JSON.stringify({
        user_id: userId,
        email_id: emailId,
        token
      }))
        .then((response) => {
          if (response.data.success) {
            this.emailVerificationStatus = true;
            new ToastBuilder().setTitle("Email Confirmed")
              .setText("You have successfully confirmed your email.")
              .setToastType(ToastBuilder.TOAST_TYPE_SUCCESS)
              .setTimeout(ToastBuilder.TIMEOUT_NONE)
              .setClosable(true)
              .build();
            // @TODO redirect to homepage
          } else {
            this.emailVerificationStatus = false;
            new ToastBuilder().setTitle("Email Confirmation Failed")
              .setText("Incorrect token")
              .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
              .setTimeout(5000)
              .setClosable(true)
              .build();
          }
          return response.data.success;
        });
    },

    passwordChange(userId, password, newPassword, newPasswordRepeated) {
      $axios.post(`/user/${userId}/password/change`, {
        new_password: newPassword,
        new_password_repeated: newPasswordRepeated,
        current_password: password,
      })
        .then(() => {
          window.location.href = "/";
        }).catch((error) => {
            new ToastBuilder().setTitle("Password Change Error")
            .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
            .setText(error.response.data.message)
            .setTimeout(ToastBuilder.TIMEOUT_NONE)
            .setClosable(true)
            .build();
        });
    },

  }
});
