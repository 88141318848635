<template>
  <div>
    user_id: {{ userId }}
    email_id: {{ emailId }}
    token: {{ token }}
    <div>Status : {{ emailVerificationStatus }}</div>
    <div v-if="emailVerificationStatus">
      You have successfully confirmed your email address
    </div>
    <div v-else>
      Your token is incorrect.
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapWritableState } from "pinia";
import { useUserStore } from "@/stores/user-store";
  
export default {
  name: "VerifyEmail",
  props: {
    userId: {
      type: String,
      required: true,
    },
    emailId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapWritableState(useUserStore, ["emailVerificationStatus"]),
  },
  created() {
    this.verifyEmail(this.userId, this.emailId, this.token);
  },
  methods: {
    ...mapActions(useUserStore, { verifyEmail: "verifyEmail" }),
  }
};
  </script>
  
  <style scoped>
 
  </style>
